@font-face {
  font-family: 'Montserrat-Alternates-Bold';
  src: local('Montserrat_Alternates'), url("../fonts/Montserrat_Alternates/MontserratAlternates-Bold.ttf") format('truetype');
}
@font-face {
  font-family: 'Montserrat-Alternates-Medium';
  src: local('Montserrat_Alternates'), url("../fonts/Montserrat_Alternates/MontserratAlternates-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url("../fonts/Montserrat/Montserrat-Bold.ttf") format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url("../fonts/Montserrat/Montserrat-Regular.ttf") format('truetype');
}
