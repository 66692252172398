@import '../../assets/css/variables';
@import '~bootstrap/scss/mixins';

$padding: 100px;
$aside-right-width: 280px;

.about {
  padding: 100px;
  text-align: justify;
  padding-right: $padding + $aside-right-width;
  padding-left: $aside-width + $padding;

  @include media-breakpoint-down(lg) {
    padding: 100px 40px;
    padding-right: 40px + $aside-right-width;
    padding-left: $aside-width + 40px;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 20px;
    padding-right: 20px + $aside-right-width;
    padding-left: $aside-width + 20px;
  }

  @include media-breakpoint-only(xs) {
    padding: 20px;
    padding-left: 20px;
    padding-bottom: $footer-xs-height;
  }

  .title {
    justify-content: space-between;
    display: flex;
    text-align: left;
    font-family: $font-family-sans-serif-title;
    font-size: 30px;
  }

  .p {
    margin-top: 40px;
  }

  .profilImg {
    height: 100px;
    width: 100px;
  }
}

.asideRight {
  width: $aside-right-width;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;

  @include media-breakpoint-only(xs) {
    width: 100%;
    height: 700px;
    position: initial;
  }

  .carousel {
    background-color: $green; // TODO use var
    height: 100%;
  }

  .carouselItem {
    flex-direction: column;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    img {
      padding: 20px;
    }
    p {
      padding: 0 50px;
      padding-bottom: 40px;
    }
  }
}
