@import '../../assets/css/variables';
@import '~bootstrap/scss/mixins';

$padding: 100px;

.studies {
  padding: 100px;
  padding-left: $aside-width + $padding;
  text-align: justify;

  @include media-breakpoint-only(xs) {
    padding: 20px;
    padding-left: 20px;
    padding-bottom: $footer-xs-height;
  }
}
