
.nav-link.active {
  font-weight: bold;
  color: $purple;
}

.nav-link{
  color: $dark;
  @extend .px-0;
  @extend .mx-3;
}

.content {
  padding-left: $aside-width;
}


html, body {
  height: 100%;
  background-color: $background-color;
}

#root, #root > div {
  height: 100%;
}

.carousel-control-prev, .carousel-control-next {
  align-items: flex-end;
  padding-bottom: 50px;
}

.carousel-indicators {
  padding-bottom: 30px;
}

.carousel-indicators > li {
  border-radius: 50%;
}

.carousel-h-100 {
  .carousel-inner, .carousel-item {
    height: 100%;
  }
}
