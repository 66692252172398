@import '../../assets/css/variables';
@import '~bootstrap/scss/mixins';

.container {
  padding: 20px;
  font-family: $font-family-sans-serif-title;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .logo {
    width: 70px;
    padding-right: 20px;
  }
}
