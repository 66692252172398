@import '../../assets/css/variables';
@import '~bootstrap/scss/mixins';

$padding: 70px;

.portfolio {
  padding: 100px;
  padding-left: $aside-width + $padding;
  padding-top: 50px;
  text-align: justify;

  @include media-breakpoint-only(xs) {
    padding: 20px;
    padding-left: 20px;
    padding-bottom: $footer-xs-height;
  }
}

.workDescription {
  font-style: italic;
  padding: 30px;
  padding-top: 0;
}

.modal {
  //background-color: black;
  :global {
    .modal-dialog {
      @include media-breakpoint-up(sm) {
        max-width: 600px;
      }
    }

    .modal-header {
      border-bottom: 0;
      padding: 1.3rem 3rem;
    }

    .modal-body {
      padding: 0rem 3rem 2rem 3rem;
    }
  }
}
