@import '../../assets/css/variables';
@import '~bootstrap/scss/mixins';

.container {
  background: white;
  border-radius: $border-radius;
  height: 100%;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 25px 3px rgba(134, 78, 255, 0.1);

  .innerContainer {
    height: 100%;
    flex: 1;
  }

  &:hover {
    background-color: $green;
    color: black;

    //.title {
    //  color: black;
    //}
  }

  &.hasRightContent {
    display: flex;
    flex-direction: row;
  }

  &.green {
    background-color: $green;
  }

  &.purple {
    background-color: $purple;
  }

  &.white {
    background-color: white;
  }

  &.black {
    background-color: black;
  }
}

.title {
  font-family: $font-family-sans-serif-title, sans-serif;
  font-size: 16px;
  padding-bottom: 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;

  &.green {
    color: $green;
  }

  &.purple {
    color: $purple;
  }

  &.white {
    color: white;
  }

  &.black {
    color: black;
  }
}

.content2 {
  font-family: $font-family-sans-serif, sans-serif;
  font-size: 13px;
  margin-bottom: 0;

  &.white {
    color: white;
  }

  &.black {
    color: black;
  }
}

.content {
  font-family: $font-family-sans-serif, sans-serif;
  font-size: 13px;
  text-wrap: normal;

  &.white {
    color: white;
  }

  &.black {
    color: black;
  }
}

.list {
  display: flex;
  flex: 1;
  padding-left: 14px;
  text-align: left;
  height: calc(100% - 30px);
  align-items: stretch;
  flex-direction: column;
  justify-content: space-around;

  &.white li {
    color: white;
  }

  &.black {
    color: black;
  }

  li {
    display: flex;
    align-items: center;
    font-family: $font-family-sans-serif, sans-serif;
    font-size: 13px;
    list-style: none;

    .bullet {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
  }
}

.date {
  background-color: white;
  width: 80px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: $font-family-sans-serif-title, sans-serif;
  font-size: 20px;
  padding: 10px;
  border-radius: 10%;
  text-align: center;
  box-sizing: content-box;

  &.white {
    background-color: $green;
    color: white;
  }

  &.green {
    background-color: white;
    color: $green;
  }
}

.rightContent {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkTo {
  &:hover {
    text-decoration: none;
  }
}

.logosContainer {
  //background-color: black;
  display: flex;
  flex-direction: row;
  //flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  padding-top: 40px;

  .logo {
    height: 60px;
    width: 60px;

    @include media-breakpoint-down(lg) {
      height: 50px;
      width: 50px;
    }

    @include media-breakpoint-down(sm) {
      height: 40px;
      width: 40px;
    }

    border-radius: 10px;
  }
}
