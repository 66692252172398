@import '../../assets/css/variables';
@import '~bootstrap/scss/mixins';

.asideMobile {
  a {
    color: black;
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    height: $footer-xs-height;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .footerLinkWrapper {
    }
  }
}

.aside {
  width: $aside-width;
  position: fixed;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 20px 4px 54px 0px rgba(100, 28, 255, 0.1);

  .logo {
    background-color: white;
    color: black;
    display: block;
  }

  .header {
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 30px;
  }

  .links {
    margin-top: 50px;
    width: 100%;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 0;
  }

  li {
    list-style: none;
  }

  .nav {
    color: black;
    list-style: none;
    font-family: $font-family-sans-serif-nav, sans-serif;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
  }

  .activenav {
    color: $purple;
  }
}
