@import '../../assets/css/variables';
@import '~bootstrap/scss/mixins';

$padding: 100px;

.skills {
  padding: 100px;
  padding-left: $aside-width + $padding;
  text-align: justify;

  @include media-breakpoint-down(lg) {
    padding: 100px 40px;
    padding-left: $aside-width + 40px;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 20px;
    padding-left: $aside-width + 20px;
  }

  @include media-breakpoint-only(xs) {
    padding: 20px;
    padding-left: 20px;
    padding-bottom: $footer-xs-height;
  }
}

.cardWrapper {
  $height-lg: 280px;
  $height-down-lg: 240px;

  height: $height-lg;
  margin-bottom: 20px;

  @include media-breakpoint-down(lg) {
    height: $height-down-lg;
  }

  &.large {
    height: 2 * $height-lg + 20px;

    @include media-breakpoint-down(lg) {
      height: 2 * $height-down-lg + 20px;
    }
  }
}
